@font-face {
	font-display: swap;
	font-family: 'Heading1';
	src: url(./jost-3-cufonfonts/Jost-SemiBold.ttf) format('truetype');
	/* fontweight:700; */
	/* font-size: 64px; */
	/* h1  */
}
@font-face {
	font-display: swap;
	font-family: 'Heading2';
	src: url(./jost-3-cufonfonts/Jost-Regular.ttf) format('truetype');
	/* font-weight:400; */
	/* font-size: 48px; */
	/* h1  */
}
@font-face {
	font-display: swap;
	font-family: 'Heading3';
	src: url(./jost-3-cufonfonts/Jost-Light.ttf) format('truetype');
	/* font-weight:300; */
	/* font-size: 36px; */
	/* h2 */
}
@font-face {
	font-display: swap;
	font-family: 'Subheading';
	src: url(./jost-3-cufonfonts/Jost-ExtraLight.ttf) format('truetype');
	/* font-weight:200; */
	/* font-size: 32px; */
	/* h3 */
}
@font-face {
	font-display: swap;
	font-family: 'Logo';
	src: url(./jost-3-cufonfonts/Jost-ExtraLight.ttf) format('truetype');
	/* font-weight:300; in design*/
	/* font-weight:200; in development for it to look the same*/
	/* font-size: 28px; */
	/* p */
}
@font-face {
	font-display: swap;
	font-family: 'Button';
	src: url(./jost-3-cufonfonts/Jost-Medium.ttf) format('truetype');
	/* font-weight:500; */
	/* font-size: 22px; */
	/* font-size: 16px; in form*/
	/* p */
}
@font-face {
	font-display: swap;
	font-family: 'Body';
	src: url(./jost-3-cufonfonts/Jost-Regular.ttf) format('truetype');
	/* font-weight:400; */
	/* font-size: 16px; */
	/* p */
}
@font-face {
	font-display: swap;
	font-family: 'SmallText';
	src: url(./jost-3-cufonfonts/Jost-Light.ttf) format('truetype');
	/* font-weight:300; */
	/* 	font-size: 14px; */
	/* p */
}
/* Jost-Thin */
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-Thin.ttf) format('truetype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-ThinItalic.ttf) format('truetype');
	font-weight: 100;
	font-style: italic;
}
/* Jost-ExtraLight */
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-ExtraLight.ttf) format('truetype');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-ExtraLightItalic.ttf) format('truetype');
	font-weight: 200;
	font-style: italic;
}
/* Jost-Light */
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-Light.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-LightItalic.ttf) format('truetype');
	font-weight: 300;
	font-style: italic;
}
/* Jost-Regular */
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-Regular.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-Italic.ttf) format('truetype');
	font-weight: 400;
	font-style: italic;
}
/* Jost-Medium */
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-Medium.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-MediumItalic.ttf) format('truetype');
	font-weight: 500;
	font-style: italic;
}
/* Jost-SemiBold */
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-SemiBold.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-SemiBoldItalic.ttf) format('truetype');
	font-weight: 600;
	font-style: italic;
}
/* Jost-Bold */
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-SemiBold.ttf) format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-SemiBold.ttf) format('truetype');
	font-weight: 700;
	font-style: italic;
}
/* Jost-ExtraBold.ttf */
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-ExtraBold.ttf) format('truetype');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-ExtraBoldItalic.ttf) format('truetype');
	font-weight: 800;
	font-style: italic;
}
/* Jost-Black */
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-Black.ttf) format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-display: swap;
	font-family: 'Jost';
	src: url(./jost-3-cufonfonts/Jost-BlackItalic.ttf) format('truetype');
	font-weight: 900;
	font-style: italic;
}
